// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61700,
  JSSThemeVariables61700,
  getJSSThemePatterns61700,
} from './JSSTheme61700';
import { GeoblockPage61700 } from './GeoblockPage61700';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop61700').then((module) => module.FullDesktop61700),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile61700').then((module) => module.FullMobile61700),
  }),

  JSSTheme: JSSTheme61700,
  JSSThemeVariables: JSSThemeVariables61700,
  JSSThemePatterns: getJSSThemePatterns61700,
  GeoblockPage: GeoblockPage61700,
});
